import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import { getProjects } from '../store/actions/projectActions';
import { Link } from 'react-router-dom';
import './Header.scss';
import Bulb from './../../svg/bulb.svg';

export type Props = {

}

class Header extends Component<Props>{

    componentDidMount(): void {
        const theme = this.getCookie('theme')
        if(theme==='dark'){
            this.toggleLights();
        }
    }

    toggleLights = () => {
        const root:any = document.getElementById('root');
        if(root.classList.contains('nri--dark')){
            this.setCookie('theme','light')
            root.classList.remove('nri--dark')
        }else{
            this.setCookie('theme','dark')
            root.classList.add('nri--dark')
        }

    }

    setCookie = (id,val) => {
        let date = new Date();
        date.setTime(date.getTime() + (5*24*60*60*1000));
        let expires = "; expires=" + date.toUTCString();
        document.cookie = id + "=" + (val || "")  + expires + "; path=/";
    }

    getCookie = (id) => {
        let nameEQ = id + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    render() {
        return (
            <header className={'header'}>
                <div className={'header__bulb'} onClick={this.toggleLights}>
                    <img src={Bulb} alt='Dark Mode'/>
                </div>
                <div className={'header__top-logo'}>
                    <Link to={'/'}>
                        <img src={"img/logo.png"} alt={'Nick Rick Interactive Logo'}/>
                    </Link>
                </div>
                <div className={'header__sub-logo'}>
                    <Link to={'/'}>
                        <img src={"img/nri.png"} alt={'Nick Rick Interactive'}/>
                    </Link>
                </div>
            </header>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        projects: state.project.projects
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProjects: () => {
            dispatch(getProjects())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header)