import * as LoadingAction from '../constants/loadingConstants';

export const showLoader = () => {
    return (dispatch,getState) => {
        dispatch({type: LoadingAction.TOGGLE_LOADING, active: true})
    }
}
export const hideLoader = () => {
    return (dispatch,getState) => {
        dispatch({type: LoadingAction.TOGGLE_LOADING, active: false})
    }
}