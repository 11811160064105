import * as LoadingAction from '../constants/loadingConstants';

const initState = {
    active:true
}

export const loadingReducer = (state: any = initState, action) => {
    let ret = {...state};
    switch(action.type){
        case LoadingAction.TOGGLE_LOADING:
            ret.active = action.active
            break;
    }
    return ret;
}