import React, {Component} from 'react';
import {HashRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import Project from './components/projects/Project';
import ProjectList from "./components/projects/ProjectList";
import {TransitionGroup,Transition} from 'react-transition-group';
import './App.scss';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Loading from "./layout/Loading";
import {getProjects} from "./store/actions/projectActions";
import {connect} from 'react-redux';
import {gsap} from 'gsap';

export type Props = {
    getProjects:Function,
    projectDir:boolean
}

class App extends Component<Props>{

    public listTop:number = 0;

    componentDidMount(): void {
        this.props.getProjects();
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        return false
    }

    transEnter = (node:HTMLElement) => {
        const dir = (this.props.projectDir);
        if(node.classList.contains('project-list-container')){
            let start = (dir) ? '-100%' : '100%'
            gsap.set(node,{x:start,position:'relative'})
            gsap.to(node,{x:'0%',duration:1,ease:'expo.inOut'})
            setTimeout(()=>{
                // gsap.to('html,body',{scrollTop:this.listTop,duration:0.5,ease:'expo.inOut'})
            },700)
        }else{
            this.listTop = document.body.scrollTop;
            let start = (dir) ? '100%' : '-100%'
            gsap.set(node,{x:start,position:'relative'})
            // gsap.to(node,{x:'0%',duration:1,ease:'expo.inOut'})
            gsap.to('html,body',{scrollTop:0,duration:0.5})
        }
    }

    transExit = (node:HTMLElement) => {
        const dir = (this.props.projectDir);
        gsap.set(node,{x:'0%',position:'absolute'})
        if(node.classList.contains('project-list-container')){
            let start = (dir) ? '-100%' : '100%'
            gsap.to(node,{x:start,duration:0.5,ease:'expo.inOut'})
        }else{
            let start = (dir) ? '100%' : '-100%'
            gsap.to(node,{x:start,duration:0.5,ease:'expo.inOut'})
        }
    }


    render(){
        const Animation = withRouter(({location}) => {
            return (
                <TransitionGroup className={'app-transition'}>
                    <Transition
                        key={location.pathname}
                        timeout={500}
                        onEnter={this.transEnter}
                        onExit={this.transExit}>
                        <Switch location={location}>
                            <Route className={'project-list-container'} exact={true} path="/" component={ProjectList}/>
                            <Route className={'project-container'} path='/project/:project_slug' component={Project}/>
                        </Switch>
                    </Transition>
                </TransitionGroup>
            )
        })
        return (
            <Router>
                <Loading/>
                <div className='nri'>
                    <div className={'nri__upper-page'}>
                        <div className={'tr-lock'}>
                            <img src="img/tr_lock.png" alt=""/>
                        </div>
                        <Header/>
                    </div>
                    <Animation/>
                </div>
                <Footer/>
            </Router>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        projectDir: state.project.projectDir
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProjects: () => {
            dispatch(getProjects())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(App)