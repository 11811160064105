import React from 'react';
import {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {showLoader, hideLoader} from "../../store/actions/loadingActions";
import {NRIProjectSummary} from "../../store/models/projectModels";
import ProjectSummary from "./ProjectSummary";
import './ProjectList.scss';

export type Props = {
    projects:NRIProjectSummary[]
    hideLoader:Function;
}

class ProjectList extends Component<Props>{

    public list:any = createRef();


    state = {
        active: false,
    }

    componentDidUpdate(prevProps: Readonly<Props>, snapshot?: any): void {
        if(this.props.projects.length>3&&!this.state.active){
            this.props.hideLoader();
            this.setState({'active':true})
        }
    }

    render() {
        const {projects} = this.props;
        return (
            <div ref={(el)=>this.list=el} className={'project-list-container project-list'}>
                {projects
                    ? projects.map((project,i) =>
                        /*<div></div>*/
                        <ProjectSummary key={project.id} num={i} alt={i%2===1} project={project} />
                    )
                    : <div> Loading... </div>
                }
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.project.projects
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => {
            dispatch(showLoader())
        },
        hideLoader: () => {
            dispatch(hideLoader())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProjectList)