import React from 'react';
import { Component } from 'react';
import {NRIProjectTechnology} from "../../store/models/projectModels";
import {gsap} from "gsap";
import './ProjectTechnology.scss';

export type Props = {
    tech:NRIProjectTechnology,
    num:number,
    isSummary?:boolean
}
class ProjectTechnology extends Component<Props>{

    private container:any = React.createRef();
    private active:boolean = false;

    componentDidMount(): void {
        if(!this.props.isSummary){
            gsap.set(this.container,{opacity:0,y:-30})
            document.body.addEventListener('scroll',this.scrolling,false)
        }
    }
    componentWillUnmount(): void {
        this.active = false;
        document.body.removeEventListener('scroll',this.scrolling,false)
    }

    scrolling = () => {
        if(this.container){
            const wh = window.innerHeight;
            const st = document.body.scrollTop;
            const rect = this.container.getBoundingClientRect()
            const y = rect.top+st;
            const h = rect.height;
            if(y>st-h&&y<st+wh){
                if(!this.active){
                    this.active = true;
                    gsap.to(this.container,{y:0,opacity:1,duration:0.5,delay:0.1*this.props.num})
                    document.body.removeEventListener('scroll',this.scrolling,false)
                }
            }
        }
    }

    fadeIn = (e) => {
        gsap.set(this.container,{opacity:1})
    }

    render() {
        const tech:NRIProjectTechnology = this.props.tech;
        return (
            <div>
                {this.props.isSummary
                    ?
                    <div key={tech.id}
                         ref={(div) => this.container = div}
                         className={`tech tech--main ${(this.props.num % 2 === 1) ? 'tech--alt' : ''}`}>
                        <div className={'tech__image'}>
                            <img className={'tech__img'} onLoad={this.fadeIn}
                                 src={`img/${tech.tech_img}`} alt={tech.alt}/>
                        </div>
                    </div>
                    :
                    <a href={tech.url} target={'_blank'} rel={'noreferrer noopener'} key={tech.id}
                        ref={(div) => this.container = div}
                        className={`tech ${(this.props.num % 2 === 1) ? 'tech--alt' : ''}`}>
                        <div className={'tech__image'}>
                            <img className={'tech__img'}
                                src={`img/${tech.tech_img}`} alt={tech.alt}/>
                        </div>
                        <strong className={'tech__copy'}>{tech.name}</strong>
                    </a>
                    }
            </div>
        )

    }
}

export default ProjectTechnology;
