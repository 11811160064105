import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { NRIProjectSummary } from "../../store/models/projectModels";
import ProjectTechnology from "./ProjectTechnology";
import './ProjectSummary.scss';
import {changeDir} from "../../store/actions/projectActions";
import {gsap} from 'gsap';

export type Props = {
    project:NRIProjectSummary,
    num:number,
    alt:boolean,
    changeDir:Function
}
class ProjectSummary extends Component<Props>{

    state = {
        loaded:false
    }

    private container:any = React.createRef();

    showAll = () => {
        setTimeout(()=>{
            if(this.state){
                this.setState({loaded:true})
            }
            document.body.dispatchEvent(new Event('scroll'))
        },50*this.props.num)
    }

    sumClick = (dir) => {
        this.props.changeDir(dir)
    }

    componentDidMount(): void {
        document.body.addEventListener('scroll',this.scrolling,false)
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('scroll',this.scrolling,false)
    }

    scrolling = () => {
        if(this.container){
            const wh = window.innerHeight;
            const st = document.body.scrollTop;
            const rect = this.container.getBoundingClientRect()
            const y = rect.top+st;
            const h = rect.height;
            if(y>st-h&&y<st+wh){
                let perc = (y-(st-h))/((st+wh)-(st-h))
                perc = (perc<0) ? 0 : (perc>1) ? 1 : perc;
                perc = (1-Math.abs(0.5-perc))*1.3;
                perc = (perc<0) ? 0 : (perc>1) ? 1 : perc;
                gsap.to(this.container,{scale:perc,duration:0.3})
/*
                console.log((y-(st-h))/((st+wh)-(st-h)))
                console.log(this.props.project.name,perc)*/
            }
        }
    }

    render() {
        const project:NRIProjectSummary = this.props.project;
        return (
            <Link to={`/project/${project.slug}`} className={`summary ${this.props.alt?'summary--alt':''}`} onClick={()=>{this.sumClick(this.props.alt)}}>
                <div className={'summary__container'} ref={(el)=>this.container=el}>
                    <div className={`summary__block ${this.state.loaded?'summary__block--active':''}`} style={{backgroundImage:`url('img/${project.banner_url}')`}}>
                        <div className={'summary__bg'}/>
                        <div className={'summary__info'}>
                            <div className={'summary__tech'}>
                                {/*<h5 className={'summary__tech__title'}> Technology Used: </h5>*/}
                                <div className={'summary__technologies'}>
                                    {project.technologies.map((tech, i)=>{
                                        return <ProjectTechnology key={tech.id} num={i} tech={tech} isSummary={true}/>
                                    })}
                                </div>
                            </div>
                            <div className={'summary__details'}>
                                <img className={'summary__details__client-logo'} src={`img/${project.client_img_url}`} alt={project.client_name}/>
                                <h2 className={'summary__details__name'}>{project.name}</h2>
                                <p className={'summary__details__desc'}>{project.short_desc}</p>
                                <strong className={'summary__details__dir'}>&gt;</strong>
                            </div>
                        </div>
                        <div className={'summary__grad'}>
                            <img src={'img/grad.png'} alt=""/>
                        </div>
                        <img className={'summary__bg-img-hide'} src={`img/${project.banner_url}`} alt={project.name} onLoad={this.showAll}/>
                    </div>
                </div>
            </Link>
        )

    }
}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeDir: (dir) => {
            dispatch(changeDir(dir))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProjectSummary)
