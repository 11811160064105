import * as ProjectAction from '../constants/projectConstants';
import {NRIProject, NRIProjectSummary} from "../models/projectModels";

const initState:{projects:NRIProjectSummary[],project:NRIProject,projectDir:boolean} = {
    projects: [
        {
            id: 1,
            banner_url: 'banners/nri.jpg',
            client: 1,
            client_img_url: '',
            client_url: '',
            client_name: 'nri',
            details: [],
            name: '',
            short_desc: '',
            slug: 'https://via.placeholder.com/350x150',
            technologies: [],
            timestamp: '',
            video_url: ''
        },
        {
            id: 2,
            banner_url: 'banners/nri.jpg',
            client: 1,
            client_img_url: '',
            client_url: '',
            client_name: 'nri',
            details: [],
            name: '',
            short_desc: '',
            slug: 'https://via.placeholder.com/350x150',
            technologies: [],
            timestamp: '',
            video_url: ''
        },
        {
            id: 3,
            banner_url: 'banners/nri.jpg',
            client: 1,
            client_img_url: '',
            client_url: '',
            client_name: 'nri',
            details: [],
            name: '',
            short_desc: '',
            slug: 'https://via.placeholder.com/350x150',
            technologies: [],
            timestamp: '',
            video_url: ''
        },
    ],
    project: {
        id: 3,
        banner_url: 'banners/nri.jpg',
        client: 1,
        client_img_url: '',
        client_url: '',
        client_name: 'nri',
        details: [],
        name: '',
        short_desc: '',
        slug: 'https://via.placeholder.com/350x150',
        technologies: [],
        timestamp: '',
        video_url: ''
    },
    projectDir: true
}

export const projectReducer = (state: any = initState, action) => {
    let ret = {...state};
    switch(action.type){
        case ProjectAction.CHANGE_PROJECT_DIR:
            ret.projectDir = action.dir
            break;
        case ProjectAction.GET_PROJECTS:
            ret.projects = action.projects
            break;
        case ProjectAction.GET_PROJECT:
            ret.project = action.project[0]
            break;
    }
    return ret;
}