import axios from "axios";
import * as ProjectAction from '../constants/projectConstants';
import {showLoader} from "./loadingActions";

export const getProjects = () => {
    return (dispatch,getState) => {
        dispatch(showLoader())
        axios.get('https://www.nickrickinteractive.com/src/handler.php',{
            params:{
                type:'site',sec:'projects'
            }})
            .then(
                (res) => {
                    dispatch({type: ProjectAction.GET_PROJECTS, projects: res.data})
                }
            )
    }
}
export const getProject = (slug:string) => {
    return (dispatch,getState) => {
        dispatch(showLoader())
        axios.get('https://www.nickrickinteractive.com/src/handler.php',{
            params:{
                type:'site',sec:'project',uid:slug
            }})
            .then(
                (res) => {
                    dispatch({type: ProjectAction.GET_PROJECT, project: res.data})
                }
            )
    }
}
export const changeDir = (dir:boolean) => {
    return (dispatch,getState) => {
        dispatch({type: ProjectAction.CHANGE_PROJECT_DIR, dir})
    }
}