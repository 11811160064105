import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { getProject } from "../../store/actions/projectActions";
import {NRIProject, NRIProjectSummary} from "../../store/models/projectModels";
import ProjectDetail from "./ProjectDetail";
import ProjectTechnology from "./ProjectTechnology";
import {gsap} from "gsap";
import {showLoader, hideLoader} from "../../store/actions/loadingActions";
import ReactHtmlParser from "react-html-parser";
import './Project.scss';

export type Props = {
    slug:string,
    project:NRIProject,
    projects:NRIProjectSummary[],
    getProject:Function,
    projectDir:string,
    showLoader:Function,
    hideLoader:Function,
    match:any,
}
class Project extends Component<Props>{

    state = {
        active: false
    }

    private videoLoaded: boolean = true;
    private projectLoaded: boolean = false;

    private container:any = React.createRef();
    private video:any = React.createRef()

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(this.props.slug!==prevProps.slug){
            if(this.state.active){
                this.projectLoaded = false;
                this.props.getProject(this.props.slug);
            }else{
                this.projectLoaded = true;
                this.animateIn()
                this.setState({active:true})
            }
        }
        if(this.props.projects.length>3){
            this.projectLoaded = true
            setTimeout(this.animateIn,1000)
        }
    }

    componentDidMount(): void {
        this.projectLoaded = false;
        const dir = (this.props.projectDir);
        gsap.set(this.container,{x:(dir) ? '100%' : '-100%'})
        this.props.getProject(this.props.slug);
    }

    componentWillUnmount(): void {
        this.projectLoaded = false;
    }

    videoComplete = () => {
        this.videoLoaded = true;
    }

    animateIn = () => {
        const dir = (this.props.projectDir);
        gsap.set(this.container,{x:(dir) ? '100%' : '-100%'})
        gsap.to(this.container,{opacity:1,x:"0%",ease:'expo.Out'})
        if(this.videoLoaded&&this.projectLoaded){
            this.props.hideLoader()
        }
    }

    animateOut = () => {
        gsap.to(this.container,{opacity:0,duration:1})
    }

    render() {
        const project:NRIProject = this.props.project;
        const projects:NRIProjectSummary[] = this.props.projects;
        const reg_exUrl:RegExp = /(?:(https?:\/\/[^\s]+))/m;
        let shortDesc = project.short_desc;
        if(reg_exUrl.test(project.short_desc)){
            shortDesc = project.short_desc.replace(reg_exUrl,'<a href="$1" target="_blank">$1</a>')
        }
        return (
            <div className={'project-container detail'} ref={(el)=>this.container=el}>
                {(project&&projects.length>3)
                    ?
                    <div className={'detail-container'}>
                        <div className={'detail-header'}>
                            <div className={'detail-header__banner'}>
                                <div className={'detail-header__bg'} style={{backgroundImage:`url('https://www.nickrickinteractive.com/img/${project.banner_url}')`}}/>
                                <div className={'detail-header__info'}>
                                    <h1 className={'detail-header__title'}>{project.name}</h1>
                                    <a href={project.client_url} target={'_blank'} rel={'noreferrer noopener'} className={'detail-header__client'}>
                                        <img className={'detail-header__client-img'} src={`img/${project.client_img_url}`} alt={project.client_name}/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {project.video_url &&
                            <div className={'detail-video__container'}>
                                <div className={'detail-video__video'}>
                                    <video className={'detail-video__video-el'} key={project.video_url} ref={(el)=>this.video=el} id={'detail-video__player'} onCanPlayThrough={this.videoComplete} width="1280" height="720" playsInline muted autoPlay loop controls={false}>
                                        <source src={`https://www.nickrickinteractive.com/${project.video_url}.mp4`} type={'video/mp4'}/>
                                    </video>
                                </div>
                            </div>
                        }

                        <div className={'detail-info'}>
                            <div className={'detail-info__title-holder'}>
                                <h2 className={'detail-info__title'}><div>{ReactHtmlParser(shortDesc)}</div></h2>
                            </div>
                            <div className={'detail-info__details'}>
                                {project.details.map((detail, i)=>{
                                    return <ProjectDetail key={detail.id} num={i} detail={detail}/>
                                })}
                            </div>
                        </div>

                        <div className={'detail-technologies'}>
                            <div className={'detail-technologies__container'}>
                                <h3 className={'detail-technologies__title'}>Technology Used</h3>
                                <div className={'detail-technologies__technologies'}>
                                    {project.technologies.map((tech, i)=>{
                                        return <ProjectTechnology key={tech.id} num={i} tech={tech}/>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>404. The project you're looking for is not here.  Go back <Link to={'/'}>Home</Link>.</div>}
            </div>
        )

    }
}

const mapStateToProps = (state, ownProps) => {
    const slug = ownProps.match.params.project_slug;
    return {
        slug,
        project: state.project.project,
        projects: state.project.projects,
        projectDir: state.project.projectDir,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProject: (id) => {
            dispatch(getProject(id))
        },
        showLoader: () => {
            dispatch(showLoader())
        },
        hideLoader: () => {
            dispatch(hideLoader())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Project)
