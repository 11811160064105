import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './ts/App';
import {createStore, applyMiddleware, compose} from "redux";
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import rootReducer from './ts/store/reducers/rootReducer';
import thunk from 'redux-thunk';

const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk)
    )
)

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
