import {combineReducers} from 'redux';
import {projectReducer} from "./projectReducer";
import {loadingReducer} from "./loadingReducer";


const rootReducer = combineReducers({
    project:projectReducer,
    loading:loadingReducer
})

export default rootReducer;