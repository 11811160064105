import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import { getProjects } from '../store/actions/projectActions';
import './Footer.scss';

export type Props = {

}

class Header extends Component<Props>{

    public Thing;

    render() {
        return (
            <footer className={'footer'}>
                <div className={'bl-lock'}>
                    <img src="img/bl_lock.png" alt=""/>
                </div>
                <div className={'footer__inner'}>

                    <div className={'footer__container'}>
                        <div className={'footer__block'}>
                            <div className={'footer__text'}>
                                <h4 className="foot_subline-h4">
                                    Need something? <strong>Let me know!</strong>
                                </h4>
                                <br/>
                                <p>
                                    Lets discuss the details over some coffee.<br/>
                                    Send me a little bit of info about what you're thinking<br/>
                                    and we can get down to business.
                                </p>
                            </div>
                        </div>
                        <div className={'footer__block'}>
                            <div className={'footer__text'}>
                                <h5 className="foot_subline-h5">Location:</h5>
                                <span>Huntington Beach, CA, USA</span>

                                <hr/>

                                <h5 className="foot_subline-h5">Email:</h5>
                                <a href="mailto:nick.rick.interactive@gmail.com" target="_blank" rel={'noopener noreferrer'}>nick.rick.interactive@gmail.com</a>

                                <hr/>

                                <h5 className="foot_subline-h5">LinkedIn:</h5>
                                <a href="http://www.linkedin.com/in/nickrickenbach" target="_blank" rel={'noopener noreferrer'}>http://www.linkedin.com/in/nickrickenbach</a>

                            </div>
                        </div>
                    </div>
                    <div className={'footer__bottom'}>
                        <strong>Nick Rick Interactive</strong> &copy; 2020.  Built with&nbsp;
                        <a href={'https://reactjs.org/'} rel={'noreferrer noopener'}>
                            React <img src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K'} alt={'ReactJS'}/>
                        </a>
                    </div>
                </div>
            </footer>
        )

    }

    handleClick = (e):void => {
        if(e){
            e.preventDefault();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.project.projects
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProjects: () => {
            dispatch(getProjects())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header)