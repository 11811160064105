import React from 'react';
import { Component } from 'react';
import {NRIProjectDetail} from "../../store/models/projectModels";
import './ProjectDetail.scss';
import {gsap} from "gsap";

export type Props = {
    detail:NRIProjectDetail,
    num:number
}
class ProjectDetail extends Component<Props>{

    private container:any = React.createRef();
    private bg:any = React.createRef();
    private active:boolean = false;

    componentDidMount(): void {
        document.body.addEventListener('scroll',this.scrolling,false)
        gsap.set(this.container,{x:(this.props.num % 2 === 1)?'-100%':'100%'})
    }

    componentWillUnmount(): void {
        this.active = false;
        document.body.removeEventListener('scroll',this.scrolling,false)
    }

    scrolling = () => {
        if(this.bg){
            const wh = window.innerHeight;
            const st = document.body.scrollTop;
            const rect = this.bg.getBoundingClientRect()
            const y = rect.top+st;
            const h = rect.height;
            if(y>st-h&&y<st+wh){
                let perc = (y-(st-h))/((st+wh)-(st-h))
                perc = (perc<0) ? 0 : (perc>1) ? 1 : perc;
                gsap.to(this.bg,{y:-100+200*perc,duration:1})
                if(!this.active){
                    gsap.to(this.container,{x:0,duration:1})
                    this.active = true;
                }
            }
        }
    }

    render() {
        const detail:NRIProjectDetail = this.props.detail;
        return (
            <div key={detail.id} ref={(el)=>{this.container=el}} className={`detail-info__detail ${(this.props.num % 2 === 1) ? 'detail-info__detail--alt' : ''}`}>
                <div className={'detail-info__detail__image'}>
                    <div className={'detail-info__detail__image-bg'} ref={(el)=>{this.bg=el}} style={{backgroundImage:`url('img/${detail.detail_img}')`}}>
                    </div>
                    <img className={'detail-info__detail__image-img'} src={`img/${detail.detail_img}`} alt={''}/>
                </div>
                <div className={'detail-info__detail__copy'}>
                    <p className={'detail-info__detail__text'}>{detail.text}</p>
                </div>
            </div>
        )

    }
}

export default ProjectDetail
