import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import { showLoader, hideLoader } from '../store/actions/loadingActions';
import {gsap} from "gsap";
import './Loading.scss';
import {ReactComponent as NRILogo} from './../../svg/nri.svg';
import {MorphSVGPlugin} from './../plugins/MorphSVGPlugin';
import {CSSPlugin} from "gsap/CSSPlugin";

export type Props = {
    active:boolean
    showLoader:Function
    hideLoader:Function
}

class Loading extends Component<Props>{

    public container:any = React.createRef();
    private icon:any = React.createRef()

    private timeline;

    private dots:string[] =  [
        '.nri-load-dot-1',
        '.nri-load-dot-2',
        '.nri-load-dot-3',
        '.nri-load-dot-4',
        '.nri-load-dot-5'
    ]
    private letters:string[] =  [
        '.nri-load-letter-n-1',
        '.nri-load-letter-n-2',
        '.nri-load-letter-i',
        '.nri-load-letter-r-1',
        '.nri-load-letter-r-2',
    ]
    private darkBlue: string = '#033B4C';
    private blue: string = '#0478A4';
    private grey: string = '#57555B';
    private red: string = '#D14C28';
    private darkRed: string = '#5B2012';

    state = {
        rand: Math.random()*893457934875345
    }

    componentDidMount(): void {
        gsap.registerPlugin(MorphSVGPlugin,CSSPlugin )
        this.timeline = gsap.timeline({repeat:-1});
        for(let i = 0; i< this.letters.length; i++){
            gsap.set(this.letters[i],{transformOrigin:'50% 50%',z:0})
            gsap.set(this.dots[i],{transformOrigin:'50% 50%',z:0})
        }
        this.timeline.to(this.letters[1],{morphSVG:this.dots[0],fill:this.darkRed,rotateZ:360,duration:1,ease:'expo.inOut'},0)
        this.timeline.to(this.letters[0],{morphSVG:this.dots[3],fill:this.red,rotateZ:-360,duration:1.2,ease:'expo.inOut'},0)
        this.timeline.to(this.letters[2],{morphSVG:this.dots[2],fill:this.grey,rotateZ:360,duration:1.4,ease:'expo.inOut'},0)
        this.timeline.to(this.letters[3],{morphSVG:this.dots[1],fill:this.blue,rotateZ:-360,duration:1.6,ease:'expo.inOut'},0)
        this.timeline.to(this.letters[4],{morphSVG:this.dots[4],fill:this.darkBlue,rotateZ:360,duration:1.8,ease:'expo.inOut'},0)
        this.timeline.to(this.letters[1],{y:-20,z:-300,duration:0.5,ease:'cubic.inOut'},1.1 - 0.3)
        this.timeline.to(this.letters[0],{y:-20,z:100,duration:0.5,ease:'cubic.inOut'},1.2 - 0.3)
        this.timeline.to(this.letters[2],{y:-20,z:200,duration:0.5,ease:'cubic.inOut'},1.3 - 0.3)
        this.timeline.to(this.letters[3],{y:-20,z:100,duration:0.5,ease:'cubic.inOut'},1.4 - 0.3)
        this.timeline.to(this.letters[4],{y:-20,z:200,duration:0.5,ease:'cubic.inOut'},1.5 - 0.3)
        this.timeline.to(this.letters[1],{y:0,z:1,duration:0.5,ease:'cubic.inOut'},1.6 - 0.3)
        this.timeline.to(this.letters[0],{y:0,z:1,duration:0.5,ease:'cubic.inOut'},1.7 - 0.3)
        this.timeline.to(this.letters[2],{y:0,z:1,duration:0.5,ease:'cubic.inOut'},1.8 - 0.3)
        this.timeline.to(this.letters[3],{y:0,z:1,duration:0.5,ease:'cubic.inOut'},1.9 - 0.3)
        this.timeline.to(this.letters[4],{y:0,z:1,duration:0.5,ease:'cubic.inOut'},2 - 0.3)
        this.timeline.to(this.letters[1],{morphSVG:this.letters[1],fill:this.red,rotateZ:720,duration:0.5,ease:'expo.inOut'},1.9)
        this.timeline.to(this.letters[0],{morphSVG:this.letters[0],fill:this.red,rotateZ:-720,duration:0.6,ease:'expo.inOut'},1.9)
        this.timeline.to(this.letters[2],{morphSVG:this.letters[2],fill:this.grey,rotateZ:720,duration:0.7,ease:'expo.inOut'},1.9)
        this.timeline.to(this.letters[3],{morphSVG:this.letters[3],fill:this.blue,rotateZ:-720,duration:0.8,ease:'expo.inOut'},1.9)
        this.timeline.to(this.letters[4],{morphSVG:this.letters[4],fill:this.blue,rotateZ:720,duration:0.9,ease:'expo.inOut'},1.9)
        this.timeline.play()
        window.addEventListener('mousemove',this.mouseMove)
    }

    mouseMove = (e) => {
        if(window.innerWidth>640){
            gsap.to(this.icon,{duration: 0.3, rotationY:((e.pageX/window.innerWidth - 0.5)*110),rotationX:((e.pageY/window.innerHeight - 0.5)*110)*-1})
        }else{
            gsap.to(this.icon,{duration: 0.1, rotationY:0,rotationX:0})
        }
    }

    render() {
        return (
            <div ref={(el)=>{this.container=el}} className={`loading ${this.props.active?'loading--active':''}`}>
                <div className={'loading__background'}/>
                <div className={'loading__graphic'}>
                    <NRILogo ref={(el)=>{this.icon=el}} className={'loading__icon'} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        active: state.loading.active
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showLoader: () => {
            dispatch(showLoader())
        },
        hideLoader: () => {
            dispatch(hideLoader())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Loading)